import { Pipe, PipeTransform } from '@angular/core';

import { isNullOrUndefined } from 'util';

const logicalChars = [
  {
    name: '>',
    fn: (a, b) => a > b,
  },
  {
    name: '>=',
    fn: (a, b) => a >= b,
  },
  {
    name: '<',
    fn: (a, b) => a < b,
  },
  {
    name: '<=',
    fn: (a, b) => a <= b,
  },
];

@Pipe({
  name: 'globalSearch',
})
export class GlobalSearchPipe implements PipeTransform {

  transform<T>(items: T[], searchText: string, keysToSearch?: string[], logicalKeysToSearch?: string[]): T[] {
    if (isNullOrUndefined(items) || items.length === 0) {
      return [];
    }
    if (isNullOrUndefined(searchText) || searchText.trim().length === 0) {
      return items;
    }
    searchText = searchText.toLowerCase().trim();

    // check logical characters
    if (logicalChars.map(logicalChar => logicalChar.name).includes(searchText[0])) {
      const searchFn = logicalChars.find(logicalChar => logicalChar.name === searchText[0]).fn;
      return items.filter(item => this.hasValue(item, searchText.slice(1).trim(), logicalKeysToSearch, searchFn));
    }

    return items.filter(item => this.hasValue(item, searchText, keysToSearch));
  }

  private hasValue(obj: any, searchTxt: string, keysToSearch?: string[], searchFn?): boolean {
    if (isNullOrUndefined(obj)) {
      return false;
    }

    if (isNullOrUndefined(searchFn) && obj === searchTxt) {
      return true;
    }

    if (!isNullOrUndefined(searchFn) && searchFn(+obj, +searchTxt)) {
      return true;
    }

    if (!isNullOrUndefined(keysToSearch) && keysToSearch.length > 0) {
      const matched = keysToSearch.map(key => obj[key]).filter(value => !isNullOrUndefined(value))
        .find(value => this.hasValue(value, searchTxt, null, searchFn));
      return !isNullOrUndefined(matched);
    }

    if (isNullOrUndefined(keysToSearch) && isNullOrUndefined(searchFn)) {
      return JSON.stringify(obj).toLowerCase().includes(searchTxt);
    }

    return false;
  }
}
