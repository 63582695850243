import { Component, Input, OnInit } from '@angular/core';

import { PopupService } from '../popup.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {

  @Input() modalConfig;

  // @Input() callBack: () => {}; // TODO


  constructor(private popupService: PopupService) {
  }

  ngOnInit() {
    // TODO
    // this.modalConfig = {
    //   isVisible: false,
    //   message: 'Your task has failed',
    //   isSuccess: false,
    // };
    // this.notificationService.getNotificationModal().subscribe(res => {
    //   this.modalConfig = res;
    // });
  }

  closeNotification() {
    this.modalConfig.display = false;
    this.modalConfig.message = null;
    // this.notificationService.toggleNotificationModal(this.modalConfig);
  }


  action() {
    this.modalConfig.callBack();
  }
}
