import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { isNullOrUndefined } from 'util';

import { AccesstokenModel, AuthRoutingParams, AuthService, AuthSessionService, LoginComponent } from '@runway/r6auth';

import { environment } from '../../../environments/environment';

export const invalidClientError = 'This user is not authorised to use this portal.';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class ExtendedLoginComponent extends LoginComponent {

  showPrivacyModal = false;

  constructor(router: Router, authService: AuthService, authSessionService: AuthSessionService, authRoutingParams: AuthRoutingParams) {
    super(router, authService, authSessionService, authRoutingParams);
    document.getElementsByClassName('footer-nav')[0].classList.add('is-hidden');
  }

  setUserSession(signonResponse: AccesstokenModel) {
    super.setUserSession(signonResponse);
    if (this.signOnSuccess) {
      const accessClientModels = this.signonRes.accessClientModels;
      if (isNullOrUndefined(accessClientModels) || accessClientModels.length === 0) {
        this.setSignOnError(invalidClientError);
        return;
      }

      if (environment.allowExternalSalesAgentOnly) {
        const externalAgent = accessClientModels.find(accessClient => accessClient.managingClientId === environment.developerClientId);
        if (isNullOrUndefined(externalAgent)) {
          this.setSignOnError(invalidClientError);
          return;
        }
        document.getElementsByClassName('footer-nav')[0].classList.remove('is-hidden');
      } else {
        const internalAgent = accessClientModels.find(accessClient => accessClient.clientId === environment.developerClientId);
        if (isNullOrUndefined(internalAgent)) {
          this.setSignOnError(invalidClientError);
          return;
        }
        document.getElementsByClassName('footer-nav')[0].classList.remove('is-hidden');
      }
    }
  }
}
