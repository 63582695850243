import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthRoutingParams, AuthSessionService, LogoutComponent } from '@runway/r6auth';

@Component({
  selector: 'app-logout',
  template: '',
})
export class ExtendedLogoutComponent extends LogoutComponent implements OnInit {

  constructor(router: Router, authSessionService: AuthSessionService, authRoutingParams: AuthRoutingParams) {
    super(router, authSessionService, authRoutingParams);
  }

  ngOnInit(): void {
    this.processSignOut();
  }
}
