import { Component, OnInit } from '@angular/core';
import { PopupService } from '../popup.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html'
})
export class ActionComponent implements OnInit {
  modalConfig;

  constructor(private popupService: PopupService) { }

  ngOnInit() {
    this.popupService.getActionModal().subscribe(res => {
      this.modalConfig = res;
    });
  }

  closeNotification() {
    this.modalConfig = null;
  }

}
