import { Component } from '@angular/core';

import { ChangePasswordComponent } from '@runway/r6auth';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ExtendedChangePasswordComponent extends ChangePasswordComponent {

}
