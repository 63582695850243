import { Component, OnInit } from '@angular/core';

import { UrlVerificationComponent } from '@runway/r6auth';

@Component({
  selector: 'app-url-verification',
  templateUrl: './url-verification.component.html',
})
export class UrlVerificationExtendedComponent extends UrlVerificationComponent implements OnInit {

}
