// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  r6ApiServiceUrl: 'https://r6dev.runwayproptech.com/runwaywsrest',
  r6AuthApiKey: 'Basic cGVycnlob21lc3I2YXBpOlAzcnJIMG0yMTA5OA==',
  mock: false,
  developerClientId: '210982910',
  repUserId : '1481751578898132813',
  customPriceEnabled: true,
  developerSecurityToken: '7376575186731473862726230212383200301578898486510',
  allowExternalSalesAgentOnly: false,
  estateZoomFactor: 1.1,
  builderSalesPortal: true,
  developerBuilderPortal: false,
  lotZoomFactor: 4,
  mockDashboard: false,
  showVpbOptions: true,
  showVpbPrice: false,
  dashboardUrl: 'https://aruv59ml3f.execute-api.us-west-1.amazonaws.com/us-staging/usdashboardservice/',
  dashboardKey: 'qE8u6gtxAy2AkuhovaIFA33BvwoXiicKGlUXHMHi',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
