import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthRouteGuard } from '@runway/r6auth';

import { EstateSummaryGetResolverService } from './resolvers/estate-summary-get-resolver.service';

import { ExtendedLoginComponent } from './auth/login/login.component';
import { ExtendedLogoutComponent } from './auth/logout/logout.component';
import { ExtendedResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UrlVerificationExtendedComponent } from './auth/url-verification/url-verification.component';

export const homePageRoute = 'community-sales-list';
export const loginRoute = 'login';
export const logoutRoute = 'logout';

const appRoutes: Routes = [
  {path: '', redirectTo: homePageRoute, pathMatch: 'full'},
  {path: loginRoute, component: ExtendedLoginComponent},
  {path: 'reset-password', component: ExtendedResetPasswordComponent},
  {path: 'urlverification', component: UrlVerificationExtendedComponent},
  {
    path: homePageRoute,
    loadChildren: () => import('./community-list/community-list.module').then(mod => mod.CommunityListModule),
    canActivate: [AuthRouteGuard],
  },
  {
    path: 'community-sales-main/:id',
    loadChildren: () => import('./community/community.module').then(mod => mod.CommunityModule),
    canActivate: [AuthRouteGuard],
    resolve: {
      estateSummary: EstateSummaryGetResolverService,
    },
  },
  {path: logoutRoute, component: ExtendedLogoutComponent, canActivate: [AuthRouteGuard]},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes,
    {
      // enableTracing: true,
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    },
  )],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
