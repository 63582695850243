import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { EstateService, EstateSummary } from '@runway/estate';

@Injectable({
  providedIn: 'root',
})
export class EstateSummaryGetResolverService implements Resolve<EstateSummary> {

  constructor(private estateService: EstateService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EstateSummary> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.estateService.getEstateSummary(id).pipe(
      take(1),
      mergeMap(estateSummary => {
        if (estateSummary) {
          return of(estateSummary);
        } else { // id not found
          this.router.navigate(['/home']);
          return EMPTY;
        }
      }),
    );
  }
}
