import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import { R6ApiParams } from '@runway/r6api';
import { AuthRoutingParams } from '@runway/r6auth';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { PopupModule } from './popup/popup.module';

import { CachingInterceptor } from './interceptors/caching-interceptor';
import { DashboardMockInterceptor } from './community/dashboard/dashboard-mock-interceptor';
import { DeveloperRequestInterceptor } from './interceptors/developer-request-interceptor';
import { NoopInterceptor } from './interceptors/noop-interceptor';

import { EnvironmentService } from './services/environment.service';

import { GlobalSearchPipe } from './pipes/global-search.pipe';

import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientJsonpModule,
    HttpClientModule,
    HttpClientXsrfModule,
    AppRoutingModule,
    AuthModule,
    PopupModule,
  ],
  providers: [
    GlobalSearchPipe,
    {provide: AuthRoutingParams, useClass: EnvironmentService},
    {provide: R6ApiParams, useClass: EnvironmentService},
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DeveloperRequestInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DashboardMockInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
