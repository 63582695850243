import { Component, OnInit } from '@angular/core';

import { isNullOrUndefined } from 'util';

import { PopupService } from '../popup/popup.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

  constructor(private popupservice: PopupService) {
  }
  currentYear: number = new Date().getFullYear();
  ngOnInit() {
    this.checkPageVisit();
  }

  checkPageVisit() {
    const view = localStorage.getItem('siteVisit');
    if (!view || isNullOrUndefined(view)) {
      localStorage.setItem('siteVisit', 'true');
      this.popupservice.toggleActionModal({
        isVisible: true,
        message: 'This website uses cookies to improve your experience. By continuing on our website, we\'ll assume you\'re ok with this.',
      });
    }
  }

}
