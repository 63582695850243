import { Component } from '@angular/core';

import { ResetPasswordComponent } from '@runway/r6auth';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ExtendedResetPasswordComponent extends ResetPasswordComponent {
}
