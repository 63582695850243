import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class DashboardMockInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (environment.mockDashboard) {
      console.log(req.url);
      if (req.url.includes('getlotsummary')) {
        return next.handle(req.clone({url: 'mock/builderlotsummary.json', method: 'get'}));
      }
      if (req.url.includes('getsalesmatrix')) {
        return next.handle(req.clone({url: 'mock/rollingsalesmatrix.json', method: 'get'}));
      }
      if (req.url.includes('getbuilderlottypelist')) {
        return next.handle(req.clone({url: 'mock/builderlottypelist.json', method: 'get'}));
      }
      if (req.url.includes('getaveragepricing')) {
        return next.handle(req.clone({url: 'mock/averagepricing.json', method: 'get'}));
      }
      if (req.url.includes('getaverageppsf')) {
        return next.handle(req.clone({url: 'mock/averageppsf.json', method: 'get'}));
      }
      if (req.url.includes('gettoprealtorsbyyear')) {
        return next.handle(req.clone({url: 'mock/toprealtorsbyyear.json', method: 'get'}));
      }
      if (req.url.includes('gettoprelocationbycity')) {
        return next.handle(req.clone({url: 'mock/toprelocationbycity.json', method: 'get'}));
      }
    }
    return next.handle(req);
  }
}
