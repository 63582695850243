import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { isNullOrUndefined } from 'util';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {

  private cache: Map<string, any> = new Map<string, any>();
  private cachableUrls: string[] = ['.svg'];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isCachableRequest(req)) {
      const value = this.cache.get(req.url);
      if (!isNullOrUndefined(value)) {
        return of(value);
      }

      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.cache.set(req.url, event);
          }
        }),
      );
    }
    return next.handle(req);
  }

  private isCachableRequest(req: HttpRequest<any>) {
    for (const url of this.cachableUrls) {
      if (req.url.includes(url)) {
        return true;
      }
    }
    return false;
  }
}
