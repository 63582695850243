import { Injectable } from '@angular/core';

import { homePageRoute, loginRoute } from '../app-routing.module';

import { environment } from '../../environments/environment';

@Injectable()
export class EnvironmentService {

  private r6SecurityToken: string;

  public getR6ApiUrl(): string {
    return environment.r6ApiServiceUrl;
  }

  public getR6SecurityToken(): string {
    return this.r6SecurityToken;
  }

  public updateR6SecurityToken(r6SecurityToken: string) {
    this.r6SecurityToken = r6SecurityToken;
  }

  public getR6AuthApiKey(): string {
    return environment.r6AuthApiKey;
  }

  public getLoginSuccessRoute(): string {
    return homePageRoute;
  }

  public getLoginErrorRoute(): string {
    return loginRoute;
  }
}
