import { Injectable } from '@angular/core';

import { Observable, Subject, ReplaySubject } from 'rxjs';

import { FileUploadModel } from '../model/file-upload.model';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private notificationModal = new Subject<any>();
  private actionModal: ReplaySubject<any> = new ReplaySubject(1);
  private fileUploadModal: Subject<any> = new Subject();
  
  toggleNotificationModal(config: any) {
    this.notificationModal.next(
      {
        isVisible: config.isVisible,
        message: config.message,
        isSuccess: config.isSuccess,
      },
    );
  }

  getNotificationModal(): Observable<any> {
    return this.notificationModal.asObservable();
  }

  toggleActionModal(config: any) {
    this.actionModal.next(config);
  }

  getActionModal(): Observable<any> {
    return this.actionModal.asObservable();
  }

  toggleFileUploadModal(config: FileUploadModel) {
    this.fileUploadModal.next(config);
  }

  getFileUploadModal(): Observable<FileUploadModel> {
    return this.fileUploadModal.asObservable();
  }
}
