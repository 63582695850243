import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { MasonryUtils } from './utils/masonry-utils';

@Component({
  selector: 'app-root',
  template: `
      <div class="page-container">
          <div class="body-section">
              <router-outlet></router-outlet>
          </div>
          <app-footer></app-footer>
      </div>
  `,
})
export class AppComponent implements OnInit {

  ngOnInit() {
    let acceptedBrowser = sessionStorage.getItem('acceptsOldBrowserUsage');
    if (acceptedBrowser === null || acceptedBrowser === undefined) {
      this.isUnsupportedBrowser();
    }
    new MasonryUtils().initAccord();
  }

  isUnsupportedBrowser() {
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE');
    if (msie > -1) {
      this.showUnsupported();
    }
    let trident = ua.indexOf('Trident/');
    if (trident > -1) {
      this.showUnsupported();
    }
  }

  showUnsupported() {
    swal.fire({
      title: 'Unsupported browser warning:',
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Proceed to site',
      allowOutsideClick: false,
      html: `
      <div class="supported-browsers">
      <p class='runway-recommends'>Runway recommends using one of the following browsers when accessing this portal. The use of an unsupported browser can have adverse effects on portal functionality and lead to significant errors in your home sales data.</p>
      <br>
      <p>Our supported browsers are:</p>
      <ul>
        <li>
          <a target='_blank' href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMIrv_zspat6wIVMD6tBh1XRAlJEAAYASAAEgKM-_D_BwE&gclsrc=aw.ds">
            <img src="https://public-runway-assets.s3.us-east-2.amazonaws.com//imageedit_31_9253407187.png" alt="">
          </a>
        </li>
        <li>
          <a target='_blank' href="https://www.mozilla.org/en-US/firefox/new/">
            <img src="https://public-runway-assets.s3.us-east-2.amazonaws.com//Firefox_logo%2C_2019.svg.png" alt="">
          </a>
        </li>
        <li>
          <a target='_blank' href="https://www.microsoft.com/en-us/edge?&OCID=AID2001283_SEM">
            <img src="https://public-runway-assets.s3.us-east-2.amazonaws.com/hillwood-assets/capture.png" alt="">
          </a>
        </li>
      </ul>
      </div>
      `,
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem('acceptsOldBrowserUsage', 'true');
      }
    })
  }
}
