import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { isNullOrUndefined } from 'util';

import { environment } from '../../environments/environment';

const developerRequests = [
  'runwaywsrest/contactprofilesapi',
  'runwaywsrest/landapi/estate/get',
  'runwaywsrest/landapi/land/publish/status',
  'runwaywsrest/crmapi',
  'runwaywsrest/homeapi',
  'runwaywsrest/houseandlandapi',
  'runwaywsrest/processapi',
  'runwaywsrest/profilesapi',
];

@Injectable()
export class DeveloperRequestInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const developerSupportedRequest = developerRequests.filter(value => req.url.includes(value));
    if (!isNullOrUndefined(developerSupportedRequest) && developerSupportedRequest.length > 0) {
      const authReq = req.clone({
        headers: req.headers.set('securitytoken', environment.developerSecurityToken),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
