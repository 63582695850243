import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionComponent } from './action/action.component';
import { AlertComponent } from './alert/alert.component';
import { DialogComponent } from './dialog/dialog.component';
import { FileUploadComponent } from './file-upload/file-upload.component';

@NgModule({
  declarations: [AlertComponent, DialogComponent, ActionComponent, FileUploadComponent],
  imports: [
    CommonModule,
  ],
  exports: [AlertComponent, DialogComponent, ActionComponent, FileUploadComponent],
})
export class PopupModule {
}
