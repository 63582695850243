declare const $: any;
declare const Masonry: any;

export class MasonryUtils {

  initMasonry() {
    this.initAccord();
    this.mutataionObserver();
  }

  initAccord() {
    $(document).on('click', '.togglebtn', function() {
      const el = $(this).parent().closest('.app-accord');
      $(this).children('i').toggleClass('rotate-180');
      el.children('.accord-body').animate({
        height: 'toggle',
      }, 0, () => {
        $('.app-boxes').masonry({
          itemSelector: '.grid-box',
        });
        el.children('.accord-body').animate({
          height: 'toggle',
        }, 0, () => {
          el.children('.accord-body').animate({
            height: 'toggle',
          }, 200, () => {
          });
        });
      });
    });
  }

  mutataionObserver() {
    const elementNode = document.getElementById('masonry-boxes') as Node;
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          $('.app-boxes').masonry({
            itemSelector: '.grid-box',
          });
        }
      }
    });

    observer.observe(elementNode, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  masonryReset() {
    new Masonry(document.querySelector('.app-boxes'), {
      itemSelector: '.grid-box'
    }).layout();
  }
}
