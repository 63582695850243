import { Component, Input, OnInit } from '@angular/core';

import { ModalConfigModel } from '../../model/modal-config.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnInit {

  @Input() modalConfig: ModalConfigModel;

  constructor() {
  }

  ngOnInit() {
  }

  closeNotification() {
    this.modalConfig.visible = false;
    this.modalConfig.message = null;
  }


  action() {
    this.modalConfig.visible = false;
    this.modalConfig.callBack(this.modalConfig.callBackParams);
  }
}
