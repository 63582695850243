import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { isNullOrUndefined } from 'util';

import { environment } from '../../environments/environment';

@Injectable()
export class NoopInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (environment.mock) {
      // if (req.url.includes('runwaywsrest/authapi/signon')) {
      //   return next.handle(req.clone({url: 'mock/auth-response.json', method: 'get'}));
      // }
      // if (req.url.includes('runwaywsrest/houseandlandapi/houseandland/create')) {
      //   return next.handle(req.clone({url: 'mock/handl-create-response.json', method: 'get'}));
      // }
      // if (req.url.includes('runwaywsrest/landapi/estatesummary/list')) {
      //   return next.handle(req.clone({url: 'mock/estate-summary-list.json'}));
      // }
      // if (req.url.includes('runwaywsrest/landapi/estatesummary/get')) {
      //   return next.handle(req.clone({url: 'mock/estate-summary.json'}));
      // }
      // if (req.url.includes('runwaywsrest/landapi/landsummary/list')) {
      //   return next.handle(req.clone({url: 'mock/land-summary-list.json'}));
      // }
      // if (req.url.includes('runwaywsrest/landapi/landsummary/get')) {
      //   return next.handle(req.clone({url: 'mock/land-summary.json'}));
      // }
      // if (req.url.includes('runwaywsrest/landapi/land/get')) {
      //   return next.handle(req.clone({url: 'mock/land-detail.json'}));
      // }
      // if (req.url.includes('runwaywsrest/homeapi/rangedetail/list')) {
      //   return next.handle(req.clone({url: 'mock/range-list.json'}));
      // }
      // if (req.url.includes('runwaywsrest/homeapi/range/get')) {
      //   return next.handle(req.clone({url: 'mock/range-detail.json'}));
      // }
      // if (req.url.includes('runwaywsrest/homeapi/homesummary/get')) {
      //   return next.handle(req.clone({url: 'mock/home-summary.json'}));
      // }
      // if (req.url.includes('runwaywsrest/homeapi/compatiblehomesummary/list')) {
      //   return next.handle(req.clone({url: 'mock/compatible-homes.json'}));
      // }
      // if (req.url.includes('runwaywsrest/houseandlandapi/houseandlandsummary/get')) {
      //   return next.handle(req.clone({url: 'mock/handl-summary.json'}));
      // }
      // if (req.url.includes('runwaywsrest/houseandlandapi/houseandlandsummary/list')) {
      //   return next.handle(req.clone({url: 'mock/handl-summary-list.json'}));
      // }
      // if (req.url.includes('runwaywsrest/processapi/salesprocess/get')
      //   && !isNullOrUndefined(req.params.get('clientproductid')) && req.params.get('clientproductid').trim().length > 0) {
      //   return next.handle(req.clone({url: 'mock/sales-process-draft.json'}));
      // }
      // if (req.url.includes('runwaywsrest/processapi/salesprocess/get')
      //   && !isNullOrUndefined(req.params.get('lotcpid')) && req.params.get('lotcpid').trim().length > 0) {
      //   return next.handle(req.clone({url: 'mock/sales-process-lot.json'}));
      // }
      if (req.url.includes('runwaywsrest/displayapi/vpb/list')) {
        return next.handle(req.clone({url: 'mock/vpb-list.json', method: 'get'}));
      }
    }
    return next.handle(req);
  }
}
