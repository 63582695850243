import { Component, OnInit } from '@angular/core';

import { AssetUploadResponse, ProductCommonService } from '@runway/productcommon';

import { PopupService } from '../popup.service';

import { FileUploadModel } from '../../model/file-upload.model';
import { ResultModel } from '../../model/result.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  private static readonly MEGA_BYTES = 1000000;
  private static readonly S3_PATH = 's3Path';

  errorMessage: string;
  filename: string;
  fileUploadModel: FileUploadModel;
  isUploadButtonDisabled: boolean;
  uploading: boolean;
  s3Url: string;

  constructor(private productCommonService: ProductCommonService,
              private popupService: PopupService) {
    this.init();
  }

  ngOnInit() {
    this.init();
    this.popupService.getFileUploadModal().subscribe(res => {
      this.fileUploadModel = res;
    });
  }

  close() {
    this.fileUploadModel = null;
    this.init();
  }

  init(): void {
    this.errorMessage = '';
    this.filename = null;
    this.isUploadButtonDisabled = true;
    this.uploading = false;
    this.s3Url = null;
  }

  selectFile($event): void {
    const files = $event.target.files;
    if (files.length > 0) {
      this.init();
      const file = files[0];
      if (this.isValid(file)) {
        this.filename = file.name;
        this.uploading = true;
        this.productCommonService.uploadAsset(file).subscribe(
          (assetUploadResponse: AssetUploadResponse) => {
              this.s3Url = assetUploadResponse[FileUploadComponent.S3_PATH];
              this.isUploadButtonDisabled = false;
              this.uploading = false;
          },
          (err) => {
            this.error('There was an error processing the file. Please try again later.');
            this.uploading = false;
          }
        );
      }
    }
  }

  upload(): void {
    this.fileUploadModel.uploadHandler(this.s3Url).subscribe(
      (res: ResultModel) => {
        this.close();
      },
      (err: ResultModel) => {
        this.error(err.message);
      }
    );
  }

  isVisible(): boolean {
    return (this.fileUploadModel && this.fileUploadModel.isVisible);
  }

  changeFileSelect(event): void {
    event.preventDefault();
    const fileInput: HTMLElement = document.getElementById('file-input') as HTMLElement;
    fileInput.click();
  }

  private error(message: string): void {
    this.errorMessage = message;
    this.isUploadButtonDisabled = true;
  }

  private isValid(file: File): boolean {
    if (file.size > this.fileUploadModel.maxFileSizeBytes) {
      const maxSizeMegaBytes = this.fileUploadModel.maxFileSizeBytes / FileUploadComponent.MEGA_BYTES;
      this.error(`File cannot be larger than ${maxSizeMegaBytes} MB`);
      return false;
    }
    return true;
  }
}
