export class ModalConfigModel {
  message: string;
  visible: boolean;
  callBack: (params) => any;
  callBackParams: any;
  fields: any;

  constructor() {
    this.visible = false;
  }
}
