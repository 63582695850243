import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { R6AuthModule } from '@runway/r6auth';

import { ExtendedChangePasswordComponent } from './change-password/change-password.component';
import { ExtendedLoginComponent } from './login/login.component';
import { ExtendedLogoutComponent } from './logout/logout.component';
import { ExtendedResetPasswordComponent } from './reset-password/reset-password.component';
import { UrlVerificationExtendedComponent } from './url-verification/url-verification.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    R6AuthModule,
  ],
  declarations: [ExtendedLoginComponent, ExtendedLogoutComponent, ExtendedResetPasswordComponent, ExtendedChangePasswordComponent, UrlVerificationExtendedComponent],
  exports: [ExtendedLoginComponent, ExtendedLogoutComponent],
  providers: [],
})
export class AuthModule {
}
